import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal, Input } from "components/UI";
import { useLocation } from "react-router-dom";
import styles from "./Login.module.css";
import { useNavigate } from "react-router-dom";
import {
  faSignInAlt,
  faSignOutAlt,
  faSignOut,
  faSignIn,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

type LoginProps = {
  schema: string;
  onLogin?: (user: { name: string }) => void;
  currentUser?: { name: string } | string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function LoginHooks(props: LoginProps) {
  const { schema, onLogin } = props;
  const navigate = useNavigate();
  const location = useLocation();
  interface StateInterface {
    show: string;
    email: string;
    password: string;
    schema: string;
    schemas: string[];
    error: string;
    user_name: string;
    active: boolean;
  }

  const initialState: StateInterface = {
    show: "",
    email: "",
    password: "",
    schema: props.schema,
    schemas: [],
    error: "",
    user_name: "",
    active: false,
  };
  const [state, setState] = useState(initialState);

  const fetchData = useCallback(async () => {
    props.setLoading(true);
    if (schema) {
      try {
        const response = await fetch("/backend/login", {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.status !== 200) throw Error("Неавторизован");
        const user = await response.json();
        if (onLogin) {
          onLogin(user);
          setState({ ...state, user_name: user["name"] });
          if (
            location.pathname === "/" ||
            location.pathname === "/forms" ||
            location.pathname === "/authrequired"
          ) {
            navigate("/forms");
          }
          props.setLoading(false);
        }
      } catch (err) {
        props.setLoading(false);
      }
    }
  }, [onLogin, schema, state]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function toggleHandler() {
    setState({ ...state, show: "login" });
    setState({ ...state, active: !state.active });
  }
  async function handleSubmit(event: React.FormEvent) {
    setState({ ...state, error: "" });
    const form = event.target as HTMLFormElement;
    event.preventDefault();

    if (!state.schema) {
      // Запрашиваем доступные схемы
      try {
        const response = await fetch("/backend/schemas", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa(state.email + ":" + state.password),
          },
        });
        if (response.status !== 200)
          throw Error("Неправильный логин или пароль");
        const body = await response.json();
        if (!Array.isArray(body) || !body.length)
          throw Error("Схемы недоступны");
        // Если доступна одна схема, то устанавливаем её и продолжаем выполнение функции
        if (body.length === 1) setState({ ...state, schema: body[0].name });
        // Показать список схем с описанием и прекращаем выполнение функции
        else setState({ ...state, schemas: body });
        return;
      } catch (err) {
        // Отобразить описание ошибки
        //@ts-ignore
        this.setState({ ...state, error: err.message });
        return;
      }
    }
    const schema_url =
      "//" +
      (props.schema === state.schema ? "" : state.schema + ".") +
      window.location.host;

    form.action = schema_url + "/backend/login";
    form.submit();
  }

  return (
    <>
      <Button
        type="submit"
        variant={state.user_name ? "danger" : "primary"}
        icon={state.user_name ? faSignOut : faSignIn}
        onClick={toggleHandler}
      >
        {state.user_name ? "выйти" : "войти"}
      </Button>
      <a href="/login/sso" rel="noreferrer" style={{ textDecoration: "none" }}>
        <Button type="button">{"Войти через SSO"}</Button>
      </a>
      <Modal active={state.active} setActive={toggleHandler}>
        <div className={styles.modal_container}>
          <form onSubmit={handleSubmit} method="post">
            <div className={styles.modal_header}>
              <span className={styles.modal_title}>Вход в систему</span>
              <Button type="button" icon={faXmark} onClick={toggleHandler} />
            </div>
            <hr className={styles.hr} />
            {state.user_name && (
              <div>
                Вы вошли в систему как <b>{state.user_name}</b>
                <hr className={styles.hr} />
              </div>
            )}
            <div className={styles.wrapper}>
              <div>
                <label className={styles.label}>Email</label>
                <Input
                  type="email"
                  name="email"
                  placeholder="Введите email"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setState({
                      ...state,
                      email: e.currentTarget.value,
                      schemas: [],
                      schema: props.schema,
                    })
                  }
                  value={state.email}
                />
              </div>
              <div>
                <label className={styles.label}>Пароль</label>
                <Input
                  type="password"
                  name="password"
                  placeholder="Пароль"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setState({
                      ...state,
                      password: e.currentTarget.value,
                      schemas: [],
                      schema: props.schema,
                    })
                  }
                  value={state.password}
                />
              </div>
            </div>
            <hr className={styles.hr} />
            <div className={styles.modal_footer}>
              <Button
                type="button"
                variant="light"
                onClick={() => setState({ ...state, active: false })}
              >
                Отмена
              </Button>
              {state.user_name ? (
                <a href="/backend/logout" className={styles.link}>
                  <Button type="button" icon={faSignOutAlt} variant="danger">
                    Выйти
                  </Button>
                </a>
              ) : (
                <Button
                  type="submit"
                  variant="primary"
                  disabled={
                    !state.email ||
                    !state.password ||
                    (!state.schema && state.schemas.length > 1)
                  }
                  icon={state.schema ? faSignInAlt : faSignInAlt}
                >
                  войти
                </Button>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
